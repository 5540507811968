import { css } from '@emotion/css';
import Link from 'gatsby-link';
import React, { ReactElement } from 'react';
import { colours } from '../styles/variables';

const tagListStyle = css`
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;

  li {
    margin-bottom: 0;
  }

  li:not(:last-child) {
    margin-right: 0.6rem;
  }
`;

const tagStyle = css`
  background: ${colours.primary};
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 0.85rem;
  padding: 0.2rem 0.8rem;
  text-decoration: none;

  &:hover {
    background: ${colours.secondary};
    color: #fff;
    transition: background 0.2s ease;
  }
`;

interface Props {
  tags: string[];
}

const TagList = ({ tags }: Props): ReactElement =>
  tags.length > 0 ? (
    <ul className={tagListStyle}>
      {tags.map((tag) => {
        const url = `/tags/${tag.replace(' ', '-').toLowerCase()}`;

        return (
          <li key={tag}>
            <Link to={url} className={tagStyle}>
              {tag}
            </Link>
          </li>
        );
      })}
    </ul>
  ) : null;

export default TagList;
